<template>
  <v-container
    fluid
    class="exp-list"
  >
    <v-data-table
      :headers="expTableHeaders"
      :items="experiments"
      :options.sync="expTableOptions"
      :loading="loading"
      item-key="id"
      :items-per-page-items="rowsPerPageOptions"
      class="elevation-1"
    >
      <template #item="{ item }">
        <tr
          :class="item.hidden === '1' ? 'text--disabled' : ''"
          :style="item.hidden === '1' ? 'text-decoration: line-through' : ''"
          @click="openExpDetail(item)"
        >
          <td>{{ item.id }}</td>
          <td>{{ item.title }}</td>
          <td>{{ item.time ? item.time.format('[On] ll [at] LT') : 'No Data' }}</td>
          <td>{{ item.ledString }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.user }}</td>
        </tr>
      </template>
    </v-data-table>

    <!-- This is the add new LED floating action button -->
    <v-btn
      dark
      fab
      bottom
      right
      fixed
      class="fab-bottom-right"
      color="accent"
      @click="openNewExp"
    >
      <v-icon>mdi-plus</v-icon>
      <span class="fab-text">
        New experiment
      </span>
    </v-btn>

    <!-- The run experiment fullscreen dialog -->
    <v-dialog
      v-model="expDialogOpen"
      scrollable
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card
        tile
        class="run-exp-dialog"
      >
        <v-app-bar
          flat
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="closeExp"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $route.meta.title }}</v-toolbar-title>
        </v-app-bar>
        <v-card-text>
          <RunExp ref="run_exp_dialog" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <ExpDetailDialog
      :dialog-open="expDetailOpen"
      :exp-i-d="expDetailID"
      :details-to-show="expDetailsToShow"
      @closeClicked="expDetailOpen = false"
    />
  </v-container>
</template>

<script>
import { EventBus } from '../scripts/event_bus'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    RunExp: () => import('../components/RunExp'),
    ExpDetailDialog: () => import('../components/ExpDetailDialog')
  },
  beforeRouteUpdate (to, from, next) {
    if (from.name === 'run_exp' && to.name === 'run_exp' && to.params.exp_id === 'new') {
      // Navigating from run exp to run exp, so probably going back after starting a new exp that got an
      // id number and thus a new route. Skip going back to new and go right to the exp list.
      console.log('Attempting to navigate back to a new exp from a running exp, bypassing to exp list instead')

      this.$router.replace({ name: 'new_exp' })
    } else if (to.name === 'new_exp') {
      // Navigating to the exp list, so refresh it
      next()
      this.refreshExps()
    } else {
      next()
    }
  },
  data () {
    return {
      expTableOptions: {
        sortBy: ['id'],
        sortDesc: [true]
      },
      expTableHeaders: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'title' },
        { text: 'Time', value: 'time' },
        { text: 'LEDs', value: 'ledString' },
        { text: 'Status', value: 'status' },
        { text: 'User', value: 'user' }
      ],
      rowsPerPageOptions: [
        10, 20, 50,
        { text: 'All', value: -1 }
      ],
      expDetailOpen: false,
      expDetailID: 0,
      expDetailsToShow: []
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      experiments: 'expList'
    }),
    expDialogOpen: function () {
      return this.$route.name === 'run_exp'
    }
  },
  mounted () {
    this.refreshExps()
    EventBus.$on('refresh', this.refreshOnGlobal)
  },
  beforeDestroy () {
    EventBus.$off('refresh', this.refreshOnGlobal)
  },
  methods: {
    ...mapActions({
      refreshExps: 'refreshExpList'
    }),
    refreshOnGlobal: function () {
      // As this component is mounted when the run experiment dialog is open,
      // it should not update on global refresh unless that dialog is closed.
      if (this.$route.name === 'new_exp') {
        this.refreshExps()
      }
    },
    openExpDetail: function (exp) {
      this.expDetailID = Number(exp.id)

      this.expDetailOpen = true
    },
    openNewExp: function () {
      // Route to the run experiment dialog
      this.$router.push({ name: 'run_exp', params: { exp_id: 'new' } })
    },
    closeExp: function () {
      // Navigate back
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>

</style>
